import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAppStore } from '@src/app/context'
import Header from '@src/app/onboarding/Header'
import { toFormattedDollars } from '@src/lib'
import useMetaDescription from '@src/lib/hooks/useMetaDescription'
import useTitle from '@src/lib/hooks/useTitle'
import { logError } from '@src/lib/log'
import Button from '@ui/Button/Button'
import Typography from '@ui/Typography'
import { BrandEmailIcon } from '@ui/icons/custom'
import { GoogleIcon } from '@ui/icons/no-tint/misc'
import Spacer from '@ui/layout/Spacer'
import { themeClassNames, ThemeProvider } from '@ui/theme'

import type UniversalLoginAppState from './UniversalLoginAppState'
import UniversalLoginLegalFooter from './UniversalLoginLegalFooter'
import * as styles from './UniversalLoginSignup.css'

const UniversalLoginSignup = () => {
  const { service, electron } = useAppStore()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [referralCode, setReferralCode] = useState<string | null>(null)

  const handleOpenUrl = (url: string) => {
    if (electron) {
      electron.app?.openExternal?.(url).catch(logError)
      return
    }

    window.open(url, '_self')
  }

  useTitle('Sign up and start your free trial')
  useMetaDescription(
    'Create an account to start your Free OpenPhone Trial. Sign up with your email or Google account. Try now for Free!',
  )

  useEffect(() => {
    const inviteCode = searchParams.get('invite')
    const referralCode = searchParams.get('referral_code')
    const phoneNumber = searchParams.get('number')

    if (inviteCode) {
      service.authV2.fetchAndSetInvite(inviteCode)
    }

    if (referralCode) {
      setReferralCode(referralCode)
    }

    if (phoneNumber) {
      // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
      service.authV2.phoneNumber = phoneNumber
    }
  }, [searchParams, service.authV2])

  const { title, subtitle } = useMemo(() => {
    const coupon = service.billing.coupon
    const invite = service.authV2.invite

    if (coupon) {
      const discount = coupon.percent_off
        ? `${coupon.percent_off}%`
        : toFormattedDollars(coupon.amount_off ?? 0, {
            hideCentsIfZero: true,
          })
      const duration =
        coupon.duration === 'forever'
          ? 'forever'
          : coupon.duration === 'once'
          ? 'one-time'
          : `for ${coupon.duration_in_months} months`

      return {
        title: (
          <>
            Redeem your <br />
            {coupon.name} offer
          </>
        ),
        subtitle: (
          <>
            Save {discount} {duration}
          </>
        ),
      }
    }

    if (invite) {
      return {
        title: <>Join {invite.name ?? 'your team'} on OpenPhone</>,
        subtitle: <>Create an account to start</>,
      }
    }

    return {
      title: (
        <>
          Welcome to <br />
          OpenPhone!
        </>
      ),
      subtitle: <>Create an account to start your free trial</>,
    }
  }, [service.authV2.invite, service.billing.coupon])

  const getState = () => {
    return {
      inviteToken: service.authV2.inviteToken,
      referralCode: referralCode,
      phoneNumber: service.authV2.phoneNumber,
    }
  }

  const handleGoogleSignup = () => {
    const state = getState()

    service.authV2.auth0Client
      ?.loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
          prompt: 'select_account',
          connection: 'google-oauth2',
        },
        openUrl: handleOpenUrl,
        appState: state,
      })
      .catch(logError)
  }

  const handleEmailSignup = () => {
    const state = getState()

    const email = searchParams.get('email')

    service.authV2.auth0Client
      ?.loginWithRedirect<UniversalLoginAppState>({
        authorizationParams: {
          screen_hint: 'signup',
          connection: 'email',
          login_hint: email ?? undefined,
        },
        openUrl: handleOpenUrl,
        appState: state,
      })
      .catch(logError)
  }

  const handleNavigateToLogin = () => {
    navigate('/login')
  }

  return (
    <ThemeProvider themeKey="light">
      <Header className={themeClassNames.light} />
      <div className={cx(styles.background, themeClassNames.light)}>
        <div className={styles.card}>
          <Typography
            className={styles.title}
            variant="largeTitle"
            color="textPrimary"
            fontWeight="medium"
          >
            {title}
          </Typography>
          <Spacer height={8} />
          <Typography variant="body" color="textSecondary">
            {subtitle}
          </Typography>
          <Spacer height={24} />
          <div className={styles.actionsContainer}>
            <Button
              fullWidth
              onClick={handleGoogleSignup}
              icon={<GoogleIcon />}
              variant="outline"
            >
              <span className={styles.btnContent}>Continue with Google</span>
            </Button>
            <Button
              fullWidth
              onClick={handleEmailSignup}
              icon={<BrandEmailIcon />}
              variant="outline"
            >
              <span className={styles.btnContent}>Continue with email</span>
            </Button>
          </div>
          <Spacer height={24} />
          <Typography variant="callout" color="textSecondary">
            Already have an account?
          </Typography>
          <Spacer height={4} />
          <button onClick={handleNavigateToLogin} className={styles.linkButton}>
            Log back in
          </button>
          <Spacer height={96} />
          <UniversalLoginLegalFooter />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default observer(UniversalLoginSignup)
