import type { Device, TwilioError } from '@twilio/voice-sdk'
import { Call } from '@twilio/voice-sdk'
import type { Debugger } from 'debug'
import Debug from 'debug'
import { action, makeAutoObservable, reaction, runInAction, toJS, when } from 'mobx'

import IncomingCallUiStore from '@src/app/voice/incoming-call/IncomingCallUiStore'
import type { PhoneNumberSelection } from '@src/component/phone-number-selector/PhoneNumberSelectorController'
import { DisposeBag } from '@src/lib/dispose'
import isNonNull from '@src/lib/isNonNull'
import { logError } from '@src/lib/log'
import objectId from '@src/lib/objectId'
import { isValidNumber, toE164 } from '@src/lib/phone-number'
import permissions from '@src/permissions'
import type Service from '@src/service'
import type { DirectNumberModel } from '@src/service/model'
import { createAnonymousIdentityFromNumber, PhoneNumberModel } from '@src/service/model'
import { isDirectNumber } from '@src/service/model/DirectNumberModel'
import MemberModel from '@src/service/model/MemberModel'
import makePersistable from '@src/service/storage/makePersistable'
import type {
  ParticipantTarget,
  RecordingStatus,
  RoomInvitation,
  RoomInvitationType,
} from '@src/service/transport/voice'

import ActiveCallParticipant from './ActiveCallParticipant'
import ActiveCallParticipants from './ActiveCallParticipants'
import type Room from './Room'
import type { RoomParticipant } from './Room'
import convertSelectionToVoiceTarget from './convertSelectionToVoiceTarget'
import NullableCallInvitationError from './errors/NullableCallInvitationError'

export type ActiveCallFromType = PhoneNumberModel | DirectNumberModel

export type ActiveCallToType = {
  number: string
  userId: string | null
  type: 'number' | 'contact' | 'inbox' | 'inbox-member' | 'member'
}[]

export type ActiveCallStatus =
  | 'incoming'
  | 'ringing'
  | 'connecting'
  | 'reconnecting'
  | 'connected'
  | 'dismissed'
  | 'none'

export interface ActiveCallData {
  id: string

  /**
   * Twilio CallSid
   *
   * May be `null` for outgoing calls until they are accepted.
   */
  sid: string | null

  room: Room

  fromParticipantId?: string | null
  fromUserId: string | null
  toParticipantId?: string | null
  toUserId: string | null
  transferrerParticipantId?: string | null
  phoneMenuSelection?: PhoneMenuSelection

  status: ActiveCallStatus | null
  startedAt?: number | null
  invitationType?: RoomInvitationType | null
  inviteMessage?: string | null
  isListener?: boolean
  recordingStatus?: RecordingStatus | null
}

export type PhoneMenuSelection = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9' | '0'

export default class ActiveCall {
  readonly participants: ActiveCallParticipants
  readonly ui: {
    incomingCall: IncomingCallUiStore
  }

  error: TwilioError.TwilioError | null = null
  isMuted = false
  /**
   * Tracks when the user has initiated a reboot the last time an error occurred. This value
   * is persisted across reboots to show the correct error message.
   * It needs to be a string otherwise we can't persist it.
   */
  rebootInitiatedDate: string | null = null
  roomReady: boolean
  private roomReadyTimeout: number | null = null

  phoneMenuSelection?: PhoneMenuSelection
  /**
   * Tracks if we already have sent the `call-ended` event to avoid sending a duplicated
   * event when the user manually leaves the call. This is needed because from Twilio's
   * perspective there's no difference between leaving the call actively or passively.
   */
  private callEndedEventSent = false

  /**
   * This may not be available unless we have requested call quality feedback from the user.
   * When this is the case, the call is no longer "ongoing" but in the "none" state and has been
   * marked to request feedback from the user.
   */
  endedAt: number | null = null

  private requestQualityFeedback = false

  protected debug: Debugger
  protected disposeBag = new DisposeBag()

  /**
   * Construct an ActiveCall from an incoming connection.
   *
   * @see https://www.notion.so/openphone/Incoming-Call-Modal-a0dd8d5838144e11b20685f8fd3d7ed6#8fc156c4d637490abae9f3d7519c7d58
   */
  static async fromIncomingCall(root: Service, call: Call) {
    const { parameters, customParameters } = call
    const roomId = customParameters.get('room_id')
    if (!roomId) {
      call.reject()
      logError(new Error('No roomId found'))
      return
    }
    const sid = parameters.CallSid
    const invitationType = customParameters.get('invitation_type') as RoomInvitationType
    const isListener = customParameters.get('is_listener') === 'true'
    const phoneMenuSelection = customParameters.get('pm_selection') as
      | PhoneMenuSelection
      | undefined
    const recordingStatus =
      customParameters.get('is_recording') === 'true' ? 'in-progress' : 'stopped'

    const fromRoomParticipant = getRoomParticipant(customParameters, roomId, 'from')
    const toRoomParticipant = getRoomParticipant(customParameters, roomId, 'to')
    const transferrerRoomParticipant = getRoomParticipant(
      customParameters,
      roomId,
      'transferrer',
    )

    if (!fromRoomParticipant || !toRoomParticipant) {
      call.reject()
      logError(new Error('No fromRoomParticipant or toRoomParticipant found'))
      return
    }

    return new ActiveCall(
      root,
      call,
      {
        // @ts-expect-error unchecked index access
        id: sid,
        // @ts-expect-error unchecked index access
        sid,
        status: 'incoming',
        room: {
          id: roomId,
          participants: [
            fromRoomParticipant,
            toRoomParticipant,
            transferrerRoomParticipant,
          ].filter(isNonNull),
        },
        phoneMenuSelection,
        inviteMessage: customParameters.get('invitation_message'),
        invitationType,
        recordingStatus,
        isListener,
        fromParticipantId: fromRoomParticipant.id,
        fromUserId: fromRoomParticipant.userId ?? null,
        toParticipantId: toRoomParticipant.id,
        toUserId: toRoomParticipant.userId ?? null,
        transferrerParticipantId: transferrerRoomParticipant?.id,
      },
      true,
    )
  }

  /**
   * Construct an ActiveCall by making an outgoing call.
   *
   * @see https://www.notion.so/openphone/Starting-a-call-794f80fa47014b6fbd0787d0d3dec20e#07d623d8658749aa9e313455e4ba55cb
   */
  static async connect(
    root: Service,
    device: Device,
    from: ActiveCallFromType,
    to: ActiveCallToType,
  ) {
    const userId = root.user.current?.id

    if (!userId) {
      return
    }

    if (!to.every((participant) => isValidNumber(participant.number))) {
      throw new Error('Phone number is invalid')
    }

    const call = await device.connect({
      params: {
        ...{
          UserId: userId,
          RoomId: objectId(),
          ParticipantId: objectId(),
          To: convertActiveCallDestinationToString(to),
        },
        ...(from instanceof PhoneNumberModel
          ? {
              PhoneNumberId: from.id,
              PhoneNumber: from.number,
            }
          : {
              DirectNumberId: from.id,
              DirectNumber: from.number ?? '',
            }),
      },
      rtcConstraints: {
        audio: true,
      },
    })

    return ActiveCall.fromOutgoingCall(root, call, false)
  }

  /**
   * Construct an ActiveCall by joining an ongoing call.
   *
   * @see https://www.notion.so/openphone/Starting-a-call-794f80fa47014b6fbd0787d0d3dec20e#07d623d8658749aa9e313455e4ba55cb
   */
  static async join(
    root: Service,
    device: Device,
    fromPhoneNumberId: PhoneNumberModel,
    roomId: string,
    isListener: boolean,
  ) {
    const userId = root.user.current?.id

    if (!userId) {
      return
    }

    const call = await device.connect({
      params: {
        ...{
          UserId: userId,
          RoomId: roomId,
          ParticipantId: objectId(),
          Listener: isListener ? 'true' : 'false',
          PhoneNumberId: fromPhoneNumberId.id,
        },
      },
      rtcConstraints: {
        audio: true,
      },
    })

    return ActiveCall.fromOutgoingCall(root, call, true)
  }

  protected static async fromOutgoingCall(
    root: Service,
    call: Call,
    getRoomData: boolean,
  ) {
    const roomId = call.customParameters.get('RoomId')
    if (!roomId) {
      call.reject()
      logError(new Error('No roomId found'))
      return
    }

    const isListener = call.customParameters.get('Listener') === 'true'

    const fromUserId = call.customParameters.get('UserId') ?? null

    const to = call.customParameters.get('To')?.split('&') ?? []
    const toTriplets = to.map(
      (triplet) =>
        triplet.split(':') as [
          phoneNumber: string,
          participantId: string,
          userId: string | undefined,
        ],
    )

    const participantId = call.customParameters.get('ParticipantId')
    if (!participantId) {
      call.reject()
      logError(new Error('No participantId found'))
      return
    }

    const identifier =
      call.customParameters.get('DirectNumber') ??
      call.customParameters.get('PhoneNumber')
    if (!identifier) {
      call.reject()
      logError(new Error('No identifier found'))
      return
    }
    const userId = call.customParameters.get('UserId')

    const participants: RoomParticipant[] = [
      {
        id: participantId,
        identifier,
        userId,
        roomId,
        status: 'active',
      },
      ...toTriplets.map(
        ([phoneNumber, participantId, userId = null]): RoomParticipant => ({
          id: participantId,
          identifier: phoneNumber,
          roomId,
          status: 'ringing',
          userId,
        }),
      ),
    ]

    const outboundConnectionId = call.outboundConnectionId
    if (!outboundConnectionId) {
      call.reject()
      logError(new Error('No outboundConnectionId found'))
      return
    }

    return new ActiveCall(
      root,
      call,
      {
        id: outboundConnectionId,
        // We don't have the CallSid until Twilio establishes the call. For
        // outgoing calls, sid is set when the call is accepted.
        sid: null,
        status: 'connecting',
        room: {
          id: roomId,
          participants,
        },
        isListener,
        fromParticipantId: participants[0]?.id,
        fromUserId,
        toParticipantId: participants[1]?.id,
        // FIXME we technically know the userId
        toUserId: null,
      },
      getRoomData,
    )
  }

  constructor(
    protected readonly root: Service,

    /**
     * The Twilio Call object.
     */
    protected readonly call: Call,

    /**
     * The raw data of this active call.
     *
     * Do not access in components.
     *
     * @access module
     */
    readonly data: ActiveCallData,

    /**
     * Decides wether the construct should fetch the room data from the backend or not.
     */
    private readonly getRoomData: boolean,
  ) {
    this.debug = Debug(`op:service:voice:ActiveCall:@${this.id}`)
    this.debug('new active call (data: %O)', data)

    this.participants = new ActiveCallParticipants(this.root, this)
    this.roomReady = this.direction === 'incoming'
    this.phoneMenuSelection = data.phoneMenuSelection

    makeAutoObservable(
      this,
      {
        direction: false,
        isVerified: false,
      },
      { autoBind: true },
    )

    makePersistable(this, 'ActiveCall', {
      rebootInitiatedDate: root.storage.sync(),
    })

    this.disableCommandBar()

    this.call.on('accept', this.handleAccept)
    this.call.on('cancel', this.handleDisconnect)
    this.call.on('disconnect', this.handleDisconnect)
    this.call.on('error', this.handleError)
    this.call.on('reconnecting', this.handleReconnecting)
    this.call.on('reconnected', this.handleReconnected)
    this.call.on('reject', this.handleDisconnect)
    this.call.on('ringing', this.handleRinging)
    this.call.on('mute', this.handleMute)

    this.roomReadyTimeout = window.setTimeout(() => {
      this.setRoomAsReady()
    }, 5000)

    this.disposeBag.add(
      () => {
        if (this.roomReadyTimeout) {
          clearTimeout(this.roomReadyTimeout)
        }
      },
      reaction(
        () => this.data,
        () => {
          this.debug('data changed (%O)', toJS(this.data))
        },
        { name: 'ActiveCall.DataChanged' },
      ),
      when(
        () => this.hasActiveParticipants,
        () => {
          this.requestQualityFeedback = this.root.voice.isCallQualityFeedbackModalShown()
        },
      ),
    )

    if (this.getRoomData) {
      this.fetchRoomData()
    }

    this.ui = {
      incomingCall: new IncomingCallUiStore(
        this.participants,
        this.isListener,
        this.invitation.type,
        this.isDirectCall,
      ),
    }
  }

  get hasActiveParticipants(): boolean {
    // we do this because call.status === 'connected' is unreliable,
    // we need to make sure that at least 1 other participant is connected to the call
    // before we determine whether we show the call quality feedback modal
    return (
      this.participants.list.filter((participant) => participant.status === 'active')
        .length > 1
    )
  }

  get hasDeniedMicrophonePermissions(): boolean {
    const state = permissions.states['microphone']
    return state === 'denied'
  }

  get id() {
    return this.data.id
  }

  get sid() {
    return this.data.sid
  }

  get roomId() {
    return this.data.room.id
  }

  get direction(): 'incoming' | 'outgoing' {
    return this.call.direction === Call.CallDirection.Incoming ? 'incoming' : 'outgoing'
  }

  get isRecording(): boolean {
    return this.recordingStatus === 'in-progress'
  }

  /**
   * The status of the recording.
   * When `null`, it means we are waiting for the data from the backend
   */
  get recordingStatus(): RecordingStatus | null {
    const status = this.data.recordingStatus

    // If we have a status from the backend let's use it
    if (status) {
      return status
    }

    // If the phone number has autoRecord active we initialise to `null` since we are
    // waiting for a backend update
    if (this.phoneNumber?.settings?.autoRecord) {
      return null
    }

    // If the phone number has autoRecord disabled the recording will only be
    // started by the user, but we still want to be able to send `null` over for state changes
    return status === undefined ? 'stopped' : null
  }

  get isVerified(): boolean {
    return Boolean(this.call.callerInfo?.isVerified)
  }

  get isDirectCall(): boolean {
    return this.direction === 'incoming'
      ? isDirectNumber(this.participants.toNumber)
      : isDirectNumber(this.participants.fromNumber)
  }

  get isGroupCall(): boolean {
    return this.participants.list.length > 2
  }

  get isOnHold(): boolean {
    return (
      this.participants.externals.length > 0 &&
      this.participants.externals.every((p) => p.onHold)
    )
  }

  get hasExternalParticipants(): boolean {
    return this.participants.externals.length > 0
  }

  get isListener(): boolean {
    return !!this.data.isListener
  }

  get status() {
    return this.root.transport.online ? this.data.status : 'reconnecting'
  }

  get startedAt(): Date | null {
    return this.data.startedAt ? new Date(this.data.startedAt) : null
  }

  get invitation() {
    return {
      type: this.data.invitationType,
      message: this.data.inviteMessage,
    }
  }

  static assertInvitationType(
    value: unknown,
  ): asserts value is NonNullable<RoomInvitationType> {
    if (!value) {
      throw new NullableCallInvitationError()
    }
  }

  get phoneNumber(): PhoneNumberModel | null {
    return (
      this.root.phoneNumber.collection.find(
        (phoneNumber) =>
          phoneNumber.number ===
          (this.direction === 'incoming'
            ? this.participants.toNumber
            : this.participants.fromNumber),
      ) ?? null
    )
  }

  async accept() {
    await this.root.voice.setDefaultAudioInputDevice()
    this.call.accept()
    this.root.analytics.voice.callAnswered(this.participants.list.length)
  }

  reject() {
    this.call.reject()
    this.handleDisconnect()
  }

  dismiss() {
    this.call.ignore()
    this.data.status = 'dismissed'
  }

  leave() {
    this.sendAnalyticsCallEnded('leave')
    this.call.disconnect()
  }

  endForEveryone() {
    this.sendAnalyticsCallEnded('end-for-everyone')
    this.call.disconnect()
    return this.root.voice.room.update(this.data.room.id, {
      status: 'ended',
    })
  }

  async toggleRecord(): Promise<void> {
    if (this.recordingStatus === null) {
      return undefined
    }
    return this.isRecording ? this.pauseRecording() : this.startRecording()
  }

  async startRecording(): Promise<void> {
    if (!this.participants.current?.id) {
      return undefined
    }
    const previousRecordingStatus = this.data.recordingStatus
    this.data.recordingStatus = null
    const recordingAction =
      previousRecordingStatus === 'paused'
        ? this.root.voice.recordings.resume(
            this.data.room.id,
            this.participants.current?.id,
          )
        : this.root.voice.recordings
            .start(this.data.room.id, this.participants.current?.id)
            .then((response) => {
              this.root.analytics.voice.manualCallRecordingStarted(
                this.phoneNumber?.id ?? '',
              )

              return response
            })

    return recordingAction
      .then(
        action((response) => {
          this.data.recordingStatus = response.recordingStatus
        }),
      )
      .catch((error) => {
        this.data.recordingStatus = previousRecordingStatus
        throw error
      })
  }

  async pauseRecording(): Promise<void> {
    if (!this.participants.current?.id) {
      return undefined
    }
    const previousRecordingStatus = this.data.recordingStatus
    this.data.recordingStatus = null
    return this.root.voice.recordings
      .pause(this.data.room.id, this.participants.current?.id)
      .then(
        action(() => {
          this.data.recordingStatus = 'paused'
        }),
      )
      .catch((error) => {
        this.data.recordingStatus = previousRecordingStatus
        throw error
      })
  }

  toggleHold() {
    const onHold = !this.isOnHold
    this.data.room.participants = this.data.room.participants.map((p) => ({
      ...p,
      // FIXME: replace with `onHold: isDirectNumber(p.identifier) ? false : onHold` when we restore direct dialing
      onHold:
        this.participants.externals.find((external) => external.roomParticipant === p) &&
        p.status === 'active'
          ? onHold
          : false,
    }))
    this.root.analytics.voice.callHoldToggled(onHold)
    return this.root.voice.room.update(this.data.room.id, { onHold })
  }

  sendDigits(digits: string) {
    return this.call.sendDigits(digits)
  }

  toggleMute() {
    this.call.mute(!this.isMuted)
  }

  async transfer(
    participant: ActiveCallParticipant,
    message: string,
    fromPhoneNumberId?: string,
  ): Promise<void> {
    this.debug('transferring to new participant (participant: %O)', participant)

    const oldCount = this.participants.list.length

    if (participant.selection && this.participants.current?.id) {
      this.data.room.participants = this.data.room.participants.filter(
        (p) =>
          !(
            p.invitation?.type === 'transferred' &&
            p.identifier === participant.identifier
          ),
      )
      this.data.room.participants.push(participant.roomParticipant)

      const target: ParticipantTarget = {
        ...convertSelectionToVoiceTarget(participant.selection),
        message,
        participantId: participant.id,
      }

      const newCount = this.participants.list.length

      this.root.analytics.voice.callParticipantsAdded(
        1,
        oldCount,
        newCount,
        ['warm-transfer'],
        message.length,
        [
          participant.selection.type === 'add-number'
            ? 'number'
            : participant.selection.type,
        ],
      )

      return this.root.voice.room
        .transfer(this.data.room.id, {
          to: target,
          from: {
            participantId: this.participants.current?.id,
            phoneNumberId: fromPhoneNumberId,
          },
        })
        .catch((error) => {
          this.data.room.participants = this.data.room.participants.filter(
            (p) => participant.id !== p.id,
          )
          throw error
        })
    }
  }

  /**
   * Set the recording status from raw data.
   *
   * @access module
   */
  handleRecordingStatusUpdate(recordingStatus: RecordingStatus) {
    this.setRoomAsReady()
    this.data.recordingStatus = recordingStatus
  }

  /**
   * Fully upsert a room participant with raw data.
   *
   * @access module
   */
  upsertRoomParticipant(participantId: string, participant: RoomParticipant) {
    this.setRoomAsReady()
    const participantIndex = this.data.room.participants.findIndex(
      (p) => p.id === participantId,
    )

    if (participantIndex >= 0) {
      const oldParticipant = this.data.room.participants[participantIndex]
      this.debug('update participant (old: %O, new: %O)', oldParticipant, participant)
      this.data.room.participants[participantIndex] = participant
    } else {
      this.debug('new participant (participant: %O)', participant)
      this.data.room.participants.push(participant)

      // When the `forwardeeVia` property is set, we need to fetch the room data from the backend
      // to also update the forwarder participant so that it gets removed from the call since we
      // are not receiving this update from the websocket.
      if (participant.forwardeeVia) {
        this.fetchRoomData()
      }
    }
  }

  /**
   * Partially update a room participant with raw data.
   *
   * @access module
   */
  updateRoomParticipant(participantId: string, participant: Partial<RoomParticipant>) {
    this.setRoomAsReady()
    const participantIndex = this.data.room.participants.findIndex(
      (p) => p.id === participantId,
    )

    if (participantIndex >= 0) {
      const oldParticipant = this.data.room.participants[participantIndex]
      const newParticipant = { ...oldParticipant, ...participant }
      this.debug('update participant (old: %O, new: %O)', oldParticipant, newParticipant)
      // @ts-expect-error unchecked index access
      this.data.room.participants[participantIndex] = newParticipant
    }
  }

  private removeCallEventListeners() {
    this.call.off('accept', this.handleAccept)
    this.call.off('cancel', this.handleDisconnect)
    this.call.off('disconnect', this.handleDisconnect)
    this.call.off('error', this.handleError)
    this.call.off('reconnecting', this.handleReconnecting)
    this.call.off('reconnected', this.handleReconnected)
    this.call.off('reject', this.handleDisconnect)
    this.call.off('ringing', this.handleRinging)
    this.call.off('mute', this.handleMute)
  }

  disableCommandBar() {
    if (window.CommandBar) {
      window.CommandBar.shutdown()
    }
  }

  enableCommandBar() {
    if (window.CommandBar) {
      void window.CommandBar.boot(this.root.user.current?.id ?? null)
    }
  }

  tearDown() {
    this.enableCommandBar()
    this.removeCallEventListeners()
    this.disposeBag.dispose()
    this.participants.tearDown()
  }

  createParticipantFromSelection(
    selection: PhoneNumberSelection,
    invitation?: RoomInvitation,
    isListener?: boolean,
  ): ActiveCallParticipant {
    const identity =
      typeof selection.to === 'string'
        ? createAnonymousIdentityFromNumber(selection.to)
        : selection.type === 'inbox'
        ? selection.to.group
        : selection.to

    const participant: RoomParticipant = {
      id: objectId(),
      identifier: this.getIdentifier(selection),
      userId: selection.to instanceof MemberModel ? identity?.id : undefined,
      roomId: this.data.room.id,
      invitation,
      isListener,
      status: 'ringing',
    }

    return new ActiveCallParticipant(this.root, this, participant, selection)
  }

  private handleAccept() {
    this.data.sid = this.call.parameters.CallSid ?? null
    this.data.status = 'connected'
    this.data.startedAt = Date.now()
  }

  private handleDisconnect() {
    // TODO: The 'error' event may be emitted directly after a disconnect, so
    // this is a hack to show the error message and _then_ disconnect.
    setTimeout(
      action(() => {
        if (this.error) {
          this.sendAnalyticsCallEnded('error')
        } else if (this.data.status === 'connected') {
          this.sendAnalyticsCallEnded('completed')
          if (this.requestQualityFeedback) {
            this.root.voice.setCallQualityFeedbackRequest({
              id: this.id,
              roomId: this.roomId,
              sid: this.sid,
              endedAt: this.endedAt,
            })
          }
        }
        this.data.status = 'none'
        this.tearDown()
        this.root.voice.calls.delete(this.id)
      }),
      0,
    )
  }

  private fetchRoomData(): void {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.root.voice.room.get(this.data.room.id).then((room: Room) => {
      runInAction(() => {
        this.data.room = {
          ...this.data.room,
          ...room,
          participants: [
            ...new Map(
              [...this.data.room.participants, ...room.participants].map((p) => [
                p.id,
                p,
              ]),
            ).values(),
          ],
        }
      })
    })
  }

  private handleError(error: TwilioError.TwilioError) {
    this.error = error

    logError(error, {
      callSid: this.sid,
      callRoomId: this.roomId,
      callFrom: this.participants.fromNumber,
      callTo: this.participants.toPhoneNumber,
      callParticipants: this.participants.list.map((participant) => ({
        identifier: participant.identifier,
        userId: participant.userId,
      })),
    })
  }

  private handleReconnecting() {
    this.data.status = 'reconnecting'
  }

  private handleReconnected() {
    this.data.status = 'connected'
  }

  private handleRinging() {
    this.data.status = 'connecting'
  }

  private handleMute(isMuted: boolean) {
    this.isMuted = isMuted
  }

  private getIdentifier = (selection: PhoneNumberSelection): string => {
    switch (selection.type) {
      case 'number':
      case 'add-number':
        return selection.to
      case 'contact':
      case 'member':
      case 'inbox-member':
        return selection.via
      case 'inbox':
        return selection.to.number
    }
  }

  private setRoomAsReady() {
    this.roomReady = true
    if (this.roomReadyTimeout) {
      clearTimeout(this.roomReadyTimeout)
    }
  }

  private sendAnalyticsCallEnded(
    cause: 'leave' | 'end-for-everyone' | 'error' | 'completed',
  ) {
    if (this.callEndedEventSent) {
      return
    }

    this.endedAt = this.startedAt
      ? Math.round((new Date().getTime() - this.startedAt.getTime()) / 1000)
      : 0
    const participantsCount = this.participants.identitiesCount

    this.root.analytics.voice.callEnded(this.endedAt, participantsCount, cause)
    this.callEndedEventSent = true
  }
}

const getRoomParticipant = (
  customParameters: Map<string, string>,
  roomId: string,
  type: 'from' | 'to' | 'transferrer',
): RoomParticipant | null => {
  const id = customParameters.get(`${type}_participant_id`) ?? objectId()
  const identifier = customParameters.get(`${type}_identifier`) ?? null
  const userId = customParameters.get(`${type}_user_id`) ?? null

  if (!identifier) {
    return null
  }

  return {
    id,
    roomId,
    userId,
    identifier,
  }
}

export const convertActiveCallDestinationToString = (to: ActiveCallToType): string => {
  return to
    .map((o) => `${toE164(o.number)}:${objectId()}${o.userId ? `:${o.userId}` : ''}`)
    .join('&')
}
